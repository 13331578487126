import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../Config";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import StudentPanelMenu from "./StudentPanelMenu";
import StudentRightsCheck from "./StudentRightsCheck";
import user from "../../assets/images/user.png";
import sample_1 from "../../assets/images/sample_1.png";
import sample_2 from "../../assets/images/sample_2.png";
import moment from "moment";
function StudentUpdatePhoto(props) {

    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(localStorage.getItem("ID"));
    const [UserName, setUserName] = useState(localStorage.getItem("UserName"));
    const [MemberType, setMemberType] = useState(
        localStorage.getItem("MemberType")
    );

    const [loading, setLoading] = useState(false);
    const [photoloading, setphotoloading] = useState(false);

    const [Student, setStudent] = useState({});
    const [show, setShow] = useState(false);
    const [RequestList, setRequestList] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [imageSelected, setImageSelected] = useState(false);
    const [image, setImage] = useState('');

    const [cropper, setCropper] = useState();
    const [imageCropped, setImageCropped] = useState(false);

    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            MemberType: MemberType,
            ID: UserID

        };

        var api_config = {
            method: 'post',
            url: config.base_url + 'Student/GetStudentByID',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                // console.log(response.data.Student);
                if (response.data.status_code == 1) {
                    setStudent(response.data.Student);
                    let student_data = response.data.Student;
                    const data = {
                        AccessKey: AccessKey,
                        UserID: UserID,
                        MemberType: 'Student',
                        ID: UserID,
                        pageNo: 1,
                        pageSize: 100,
                        WingName: student_data?.SelectedClass?.SelectedWing?.Wing_Name,
                        ClassName: student_data?.SelectedClass?.Class_Name,
                        SectionName: student_data?.SelectedSection?.Section_Name,


                    };
                    // console.log(data, 'Student-----------------');



                    var api_config = {
                        method: 'post',
                        url: config.base_url + 'PhotoUpdateRequest/GetAllStudentRequests',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };




                    axios(api_config)
                        .then(function (response) {
                            // console.log(response.data, 'GetAllRequests------------');
                            if (response.data.status_code == 1) {
                                setRequestList(response.data.ListofRecords);
                            }
                            setLoading(false);
                        })
                        .catch(function (error) {
                            setLoading(false);
                            toast.error('Network Error..', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        });

                }
                else {
                    toast.error('Network Error..', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setLoading(false);
                }
            })
            .catch(function (error) {
                setLoading(false);
                toast.error('Network Error..', {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    }, [UserID,photoloading]);



    const DeleteImage = () => {
        setImageSelected(false);
        setImage('');
        setCropper();
        setShow(false)
    }

    const MAX_IMAGE_SIZE = 4096 * 1024; // 2MB in bytes



    const onChange = (e) => {
        e.preventDefault();
        setImageSelected(true);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const file = files[0];
        if (file.size > MAX_IMAGE_SIZE) {
            setImageSelected(false)
            toast.error("Image size must be less than 5 MB", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };


    const [dragging, setDragging] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDrop = (event) => {

        event.preventDefault();
        setImageSelected(true);
        setDragging(false);

        const file = event.dataTransfer.files[0];
        if (file) {

            if (file.size > MAX_IMAGE_SIZE) {

                setImageSelected(false)
                toast.error("Image size must be less than 5 MB", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
                if (cropper) {
                    cropper.replace(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    let navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        let base64_string = "";

        if (typeof cropper !== "undefined") {

            var cropData1 = cropper.getCroppedCanvas().toDataURL();
            setImageCropped(true);
            var cleanerBase64 = cropData1.substring(22);
            base64_string = cleanerBase64;


        }

        if (base64_string != "") {
            setphotoloading(true);
            const data = {
                AccessKey: AccessKey,
                UserID: UserID,
                MemberType: MemberType,
                RegistrationNo: Student.Registration_No,
                Name: Student.Student_Name,
                FatherName: Student.Father_Name,
                WingName: Student.SelectedClass?.SelectedWing?.Wing_Name,
                ClassName: Student.SelectedClass?.Class_Name,
                SectionName: Student.SelectedSection?.Section_Name,
                Student_ID: Student.Student_ID,
                Photo: base64_string,
            };
            // console.log(data, '--------------------');

            var api_config = {
                method: "post",
                url: config.base_url + "PhotoUpdateRequest/AddRequest",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(api_config)
                .then(function (response) {
                    if (response.data.status_code == 1) {
                        toast.success(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 3000);
                        setShow(false);
                        setphotoloading(false)
                    }
                    else if (response.data.status_message === "Invalid Access Key.") {
                        navigate("/login");
                    } else {
                        toast.error(response.data.status_message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setphotoloading(false);
                    }

                })
                .catch(function (error) {
                    toast.error("Network Error..", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }

    };
    const renderLedger = () => {
        if (RequestList != null && RequestList.length > 0) {
            return RequestList.map((item, index) => {

                return (
                    <>
                        <tr key={index}>
                            <td>{item.Name}</td>
                            <td>{item.FatherName}</td>
                            <td>{item.ClassName}</td>
                            <td>{item.WingName}</td>
                            <td>{item.SectionName}</td>
                            <td>{moment(item.Created_Date).format('l')}</td>
                            <td>{item.Status}</td>
                        </tr>
                    </>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan="8">
                        <h4
                            className="mt-3"
                            style={{ fontSize: "15px", color: "rgba(0,0,0,0.6)" }}
                        >
                            No Data Found
                        </h4>
                    </td>
                </tr>
            );
        }
    };

    return (
        <>
            <StudentPanelMenu />
            <ToastContainer />
            <StudentRightsCheck />
            <Modal className="modal-theme" show={show} onHide={DeleteImage}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className={`kyc-img-card pink`}>
                        <div className="card-inner">
                            <div className="upload-img gray my-3 w-100">
                                <div className="card-inner">
                                    <div className={`upload-box ${dragging ? 'dragging' : ''}`}
                                        id="drop-area"
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                    >
                                        <input id="ChoosePassportimage" accept="image/jpeg,image/png" type="file" onChange={onChange} />
                                        {
                                            imageSelected ?
                                                <>
                                                    <Cropper
                                                        // style={{ width: '550px', height: '220px;', marginTop: '-10px' }}
                                                        zoomTo={0.5}
                                                        preview=".img-preview"
                                                        src={image}
                                                        dragMode="move"
                                                        viewMode={3}
                                                        minCropBoxHeight={10}
                                                        minCropBoxWidth={10}
                                                        background={false}
                                                        responsive={true}
                                                        autoCropArea={1}
                                                        aspectRatio={4 / 5}
                                                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                        onInitialized={(instance) => {
                                                            setCropper(instance);
                                                        }}
                                                        guides={true}
                                                    />
                                                    <div className="delete" onClick={DeleteImage}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                        </svg>
                                                    </div>
                                                </>

                                                :
                                                <>
                                                    <label htmlFor="ChoosePassportimage" className='thumnail'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 3.75H6A2.25 2.25 0 0 0 3.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0 1 20.25 6v1.5m0 9V18A2.25 2.25 0 0 1 18 20.25h-1.5m-9 0H6A2.25 2.25 0 0 1 3.75 18v-1.5M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                                        </svg>
                                                        <h6>Drag and drop your document</h6>
                                                        <p>Max size 5 MB. Files allowed: JPG, PNG</p>
                                                    </label>

                                                </>

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="button button-primary" onClick={handleSubmit} disabled={loading}>Save {loading ? '...' : ''}</button>
                </Modal.Footer>
            </Modal>
            <div className="container body-content">
                <div className="page-head">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/student-panel/Dashboard">Home</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">Update Photo</li>
                                    </ol>
                                </nav>
                                <div className="page-title">Update Photo</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-content">
                    <div className="container">
                        <div className="row align-items-center gy-3">
                            <div className="col-md-4">
                                <h2>Sample Image</h2>
                                <img src={sample_1} alt="sample_1" className='img-fluid' width='100%' />
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body p-md-4">
                                        <div className="form-group">
                                            <label className="">Select Photo:</label>
                                        </div>
                                        <div className="col-md-3 w-100 text-center" style={{}}>
                                            <div className='m-auto mb-3' style={{ width: "150px", height: "150px", borderRadius: "50%", border: '1px dashed black', overflow: 'hidden' }}>
                                                <img
                                                    src={`${config.StudentImagePath}${UserName}.jpg`}
                                                    onError={(e) => e.target.src = user}
                                                    className="img-fluid w-100"
                                                    style={{ borderRadius: "50%" }}
                                                    alt="profile" />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={handleShow}
                                                className="button w-100 button-small"
                                            >
                                                Choose Photo
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <img src={sample_2} alt="sample_2" className='img-fluid mt-4' width='100%' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-content mt-3">
                    <div className="container">
                        <div className="card">
                            <div className="card-body">
                                <table className="table table-theme">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Father Name</th>
                                            <th>Class</th>
                                            <th>Wing</th>
                                            <th>Section</th>
                                            <th>Created Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td className="text-center" colSpan="8">
                                                    <Spinner animation="border" role="status"></Spinner>
                                                </td>
                                            </tr>
                                        ) : (
                                            <>{renderLedger()}</>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default StudentUpdatePhoto;